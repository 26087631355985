import React, { useState } from 'react'
import { Routes, Route, useParams } from 'react-router-dom';
import axios from 'axios'
export default function Upload() {

    let { eventid,eventname ,sub} = useParams();
    console.log( " 1233",useParams(),)

const [imgUrl ,setUrl]=useState('')
const [eventidhere ,setEventidHere]=useState('')

const [dwn1 ,setDwn1]=useState('')
const [dwn2 ,setDwn2]=useState('')

const [dwn3 ,setDwn3]=useState('')
const [title ,setTitle]=useState('')
const [apiHit ,setApiHit]=useState(true)



const getData = async () => {
    const res = await axios.post(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/eventlistenter`,{id:'ss',eventname:title,eventid:eventidhere,imgUrl})
    if(res.data._id)
    {
      setApiHit(false)

setTimeout(() => {
  window.location.reload()
}, 2000);

    
    }
  }

  const getDataSub = async () => {
    const res = await axios.post(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/eventlistentersub`,{id:'ss',eventname:title,eventid:eventidhere,imgUrl})
    if(res.data._id)
    {
      setApiHit(false)

setTimeout(() => {
  window.location.reload()
}, 2000);

    
    }
  }


  return (
    <div className='min-vh-100 w-full flex items-center justify-center h-screen '>
{!apiHit &&
<img src="https://i.pinimg.com/originals/90/13/f7/9013f7b5eb6db0f41f4fd51d989491e7.gif" alt="" />}

{apiHit &&
        <div className=' flex items-center w-8/12 justify-center flex-col  shadowHere '>

            <div className='text-4xl'>Enter Event</div>

            <div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setTitle(e.target.value)} placeholder='Event Name' value={title} type="text" />
</div> 
           
           {!sub && <div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setUrl(e.target.value)} placeholder='Image Url' value={imgUrl} type="text" />
</div>
}

{!sub && 
<div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setEventidHere(e.target.value)} placeholder='ENTER EVENT ID' value={eventidhere} type="text" />
</div>
}

{!sub && 
<div 

onClick={()=>getData(8)}
className=' w-6/12  m-1  rounded-md  bg-green-400 flex items-center justify-center text-xl text-white p-2 mt-2 cursor-pointer ' >
SUBMIT
{/* <input className='w-full  text-2xl rounded-md  ' onChange={(e)=>setDwn3(e.target.value)} placeholder='Download Url3' value={dwn3} type="text" /> */}
</div>
}
{sub && 
<div 

onClick={()=>getDataSub(8)}
className=' w-6/12  m-1  rounded-md  bg-green-400 flex items-center justify-center text-xl text-white p-2 mt-2 cursor-pointer ' >
ADD Event
{/* <input className='w-full  text-2xl rounded-md  ' onChange={(e)=>setDwn3(e.target.value)} placeholder='Download Url3' value={dwn3} type="text" /> */}
</div>
}


            
        </div>
        }



    </div>
  )
}
