import React, { useState } from 'react'
import { Routes, Route, useParams } from 'react-router-dom';
import axios from 'axios'
export default function Upload() {

    let { eventname ,eventid} = useParams();
    console.log( " eventname,eventid eventname,eventid",eventname ,eventid)

const [imgUrl ,setUrl]=useState('')
const [showurl ,setShowUrl]=useState('')

const [size ,setSize]=useState('')

const [desc ,setDesc]=useState('')

const [dwn1 ,setDwn1]=useState('')
const [dwn2 ,setDwn2]=useState('')

const [dwn3 ,setDwn3]=useState('')
const [title ,setTitle]=useState('')
const [rar ,setRar]=useState('')

const [imgType ,setImgType]=useState('big')

const [suc ,setSUc]=useState(true)


const getData = async () => {

    const res = await axios.post(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/entereventdetail`,{title:title,eventname:eventname,eventid:eventid,url:imgUrl,dwn1,rar,dwn2,dwn3,showurl,size,desc,imgType,uploadby:localStorage.getItem('userName')})

console.log('res',res.data._id)
if(res.data._id)
{
  setSUc(false)

  setTimeout(() => {
    window.location.reload()
  }, 2000);
  

}

    // if(res.d)
}



  return (
    <div className='min-vh-100 w-full flex items-center justify-center h-screen '>
 {!suc &&
<img src="https://i.pinimg.com/originals/90/13/f7/9013f7b5eb6db0f41f4fd51d989491e7.gif" alt="" />}
       {suc && <div className=' flex items-center w-8/12 justify-center flex-col  shadowHere '>

            <div className='text-4xl' style={{fontSize:'24px'}}>Enter Data for {eventname}</div>

            <div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2   ' onChange={(e)=>setTitle(e.target.value)} placeholder='Image Title' value={title} type="text" style={{fontSize:'16px'}} />
</div>

<div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2   ' onChange={(e)=>setDesc(e.target.value)} placeholder='Image Description' value={desc} type="text" style={{fontSize:'16px'}} />
</div>
           
            <div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setUrl(e.target.value)} placeholder='Thumbnail Image URL' value={imgUrl} type="text" style={{fontSize:'16px'}} />
</div>

<div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2   ' onChange={(e)=>setShowUrl(e.target.value)} placeholder='Download Image Url High Res..' value={showurl} type="text" style={{fontSize:'16px'}} />
</div>

<div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setDwn1(e.target.value)} placeholder='PSD URL Image' value={dwn1} type="text" style={{fontSize:'16px'}} />
</div>
<div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setDwn2(e.target.value)} placeholder='AI URL Image' value={dwn2} type="text" style={{fontSize:'16px'}} />
</div>
<div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setDwn3(e.target.value)} placeholder='CDR URL Image' value={dwn3} type="text" style={{fontSize:'16px'}} />
</div>
<div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setRar(e.target.value)} placeholder='RAR URL File' value={rar} type="text" style={{fontSize:'16px'}} />
</div>

<div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2   ' onChange={(e)=>setSize(e.target.value)} placeholder='Image Details' value={size} type="text" style={{fontSize:'16px'}} />
</div>

<div className='flex items-center w-6/12  m-1  rounded-md  ' >
<select id="cars" className='w-full p-2 text-2xl' onChange={(e)=>setImgType(e.target.value)} style={{fontSize:'16px'}}>
  <option value="big">Big</option>
  <option value="horizontal">Horizontal</option>
  <option value="vertical">Vertical</option>
 
</select> 


</div>
<div className='flex items-center w-6/12  m-1  rounded-md  '>

<input className='w-full  text-2xl rounded-md p-2   '  value={`uploading By ${localStorage.getItem('userName')}`} type="text" style={{fontSize:'16px'}} />
</div>

<div  onClick={()=>getData(8)}
className=' w-6/12  m-1  rounded-md  bg-green-400 flex items-center justify-center text-xl text-white p-2 mt-2 cursor-pointer ' >
SUBMIT
{/* <input className='w-full  text-2xl rounded-md  ' onChange={(e)=>setDwn3(e.target.value)} placeholder='Download Url3' value={dwn3} type="text" /> */}
</div>


            
        </div>
        }



    </div>
  )
}