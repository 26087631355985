import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import HomePage from './homepage'
import GridLayout from './new'
import { createRoot } from "react-dom/client";
import UploadList from './uploadlist'
import Upload from './upload'
import AllImg from './allimg'

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element:<HomePage/>,
  },
  {
    path: "/about",
    element:<AllImg />,
  },
  {
    path: "/gallery/:id/:eventname",
    element:<GridLayout />,
  },
  {
    path: "/gallery/all",
    element:<AllImg />,
  },
  {
    path: "/uploadList",
    element:<UploadList />,
  },
  {
    path: "/upload/:eventid/:eventname",
    element:<Upload />,
  },
  {
    path: "/uploadList/:id/:eventname/:sub",
    element:<UploadList />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);