import React from 'react'
import axios from 'axios';
const { useState, useEffect } = React;

const Modal = ({ onRequestClose, ...props }) => {
	const [suc,setSuc]=useState(true)

	const [indexNumber,setIndex]= useState(props.index)
	const [editTheme,setEditTheme]=useState(false)
	const [imgUrl ,setUrl]=useState(props.data2[indexNumber]&& props.data2[indexNumber].imgUrl)
	const [showurl ,setShowUrl]=useState(props.data2[indexNumber]&& props.data2[indexNumber].showurl)
	
	const [size ,setSize]=useState(props.data2[indexNumber]&& props.data2[indexNumber].size)
	
	const [desc ,setDesc]=useState(props.data2[indexNumber]&& props.data2[indexNumber].desc)
	
	const [dwn1 ,setDwn1]=useState(props.data2[indexNumber]&& props.data2[indexNumber].dwn1)
	const [dwn2 ,setDwn2]=useState(props.data2[indexNumber]&& props.data2[indexNumber].dwn2)
	
	const [dwn3 ,setDwn3]=useState(props.data2[indexNumber]&& props.data2[indexNumber].dwn3)
	const [title ,setTitle]=useState(props.data2[indexNumber]&& props.data2[indexNumber].title)
	const [rar ,setRar]=useState(props.data2[indexNumber]&& props.data2[indexNumber].rar)






	// console.log("data2data2data2data2data2data2",props.data2[props.index].url)
	// Use useEffect to add an event listener to the document
	useEffect(() => {
		function onKeyDown(event) {
			if (event.keyCode === 27) {
				// Close the modal when the Escape key is pressed
				onRequestClose();
			}
		}

		// Prevent scolling
		document.body.style.overflow = "hidden";
		document.addEventListener("keydown", onKeyDown);

		// Clear things up when unmounting this component
		return () => {
			document.body.style.overflow = "visible";
			document.removeEventListener("keydown", onKeyDown);
		};
	},[indexNumber,suc]);


	const UpdateData=async()=>{
		const res = await axios.post(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/eventassetdEdit`,{title:title,url:imgUrl,dwn1,rar,dwn2,dwn3,showurl,size,desc,eventid:props.data2[indexNumber]._id})
		console.log(res.data)

		if(res.data.msg=="tab added")
		{
			
			setTimeout(() => { 	window.location.reload()}, 3000);
			setSuc(false)
			
		
		}

	}

	return (
		<div className="modal__backdrop   ">
		


			<div className="modal__container flex items-center justify-center flex-col realtive ">

				{!editTheme?<>
		
			<div className='flex items-center justify-center text-black w-full  '>
				<div className='w-1/12'>

<button type="button" onClick={()=>indexNumber!=0? setIndex(indexNumber-1):null} className='m-2 rotate-180'>
	<img src="https://cdn-icons-png.flaticon.com/512/318/318476.png" alt="" />
</button>

</div>{props.data2[indexNumber].dwn1 &&
				<div className='w-1/12 flex'>
				<a href={props.data2[indexNumber].dwn1} target="_blank">
						<button type="button"  className='flex items-center justify-center bg-black m-1'>
							
							<div className='m-1'>PSD </div>
							     <img className='h-6 w6 ' alt="ImageName" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Download-Icon.png/800px-Download-Icon.png" />
						</button>
						</a>

					</div>
}

					{props.data2[indexNumber].dwn3 &&	<div className='w-1/12 flex'>
						<a href={props.data2[indexNumber].dwn3} target="_blank">
						<button type="button" className='flex items-center justify-center bg-black m-1'>
							
							<div className='m-1'>CDR </div>
							     <img className='h-6 w6 ' alt="ImageName" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Download-Icon.png/800px-Download-Icon.png" />
						</button>
						</a>


					</div>
}
{props.data2[indexNumber].dwn2 &&
					<div className='w-1/12 flex'>
					
				<a href={props.data2[indexNumber].dwn2} target="_blank">
						<button type="button" className='flex items-center justify-center bg-black m-1'>
							
							<div className='m-1'>.AI </div>
							     <img className='h-6 w6 ' alt="ImageName" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Download-Icon.png/800px-Download-Icon.png" />
						</button>
						</a>
					

					</div>
						}
					<div className='w-1/12 flex'>
					<a href={props.data2[indexNumber].showurl} target="_blank">
						<button type="button" className='flex items-center justify-center bg-black m-1'>
							
							<div className='m-1'>IMG </div>
							     <img className='h-6 w6 ' alt="ImageName" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Download-Icon.png/800px-Download-Icon.png" />
						</button>
						</a>

					</div>
					{props.data2[indexNumber].rar &&
					<div className='w-1/12 flex'>
					
				<a href={props.data2[indexNumber].rar} target="_blank">
						<button type="button" className='flex items-center justify-center bg-black m-1'>
							
							<div className='m-1'>RAR </div>
							     <img className='h-6 w6 ' alt="ImageName" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Download-Icon.png/800px-Download-Icon.png" />
						</button>
						</a>
					

					</div>
						}

<div className='w-1/12 flex'>
					
				
							<button type="button" onClick={()=>setEditTheme(true)} className='flex items-center justify-center bg-black m-1'>
								
								<div className='m-1' >Edit </div>
									 <img className='h-6 w-6 ' alt="ImageName" src="https://cdn-icons-png.flaticon.com/512/9312/9312815.png" />
							</button>
						
						
	
						</div>
					
					<div className='w-1/12'>
						<button type="button" onClick={()=>indexNumber< props.data2.length-1? setIndex(indexNumber+1):null} className='b m-2'>
							<img src="https://cdn-icons-png.flaticon.com/512/318/318476.png" alt="" />
						</button>

					</div>
				



				</div>
				

				<div className='w-1/12 top-0 right-0 m-2 absolute'>	<button type="button" onClick={()=>window.location.reload()} className='bg-black m-2'>
					X
				</button>


				</div>
				
				<h3 className="modal__title text-center text-xl p-2 capitalize" style={{ fontSize: '1.5rem' }}> {props.data2[indexNumber].title}</h3>

				<img style={{width:'50ww',height:'50vh'}}  src={props.data2[indexNumber].url} alt="" />

				  {/* <a download="download"
				 
				   href={`https://images.virtuallive.in/wp-content/uploads/2022/12/DOC-DESK-4-X-4.ai`}
				   title="ImageName">
    <img className='h-10 w-10 ' alt="ImageName" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Download-Icon.png/800px-Download-Icon.png" />
</a> */}
			  

				{/* <a download="download" href={props.data2[props.index].url}
					title="ImageName">
					<img className='h-10 w-10 ' alt="ImageName" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Download-Icon.png/800px-Download-Icon.png" />
				</a> */}
			

			<div className='flex text-base p-3 text-black   items-start w-full justify-start   font-light text-left'> <b className='text-base  font-bold px-2'>Description: </b> {props.data2[indexNumber].desc} </div>
			<div className='flex text-base p-3 text-black   items-start w-full justify-start   font-light text-left'> <b className='text-base  font-bold px-2'>Image Size: </b> {props.data2[indexNumber].size}  </div>

			<div className='flex text-base p-3 text-black   items-start w-full justify-start   font-light text-left'> <b className='text-base  font-bold px-2'>Upload By: </b> {props.data2[indexNumber].uploadby}</div>


			</>:
			<>
	{!suc &&
<img className='h-64' src="https://i.pinimg.com/originals/90/13/f7/9013f7b5eb6db0f41f4fd51d989491e7.gif" alt="" />}
		{suc && <div className=' w-full flex items-center justify-center'>

{/* {props.data2[indexNumber]._id} */}

<div className='w-1/12 top-0 right-0 m-2 absolute'>	<button type="button" onClick={()=>window.location.reload()} className='bg-black m-2'>
					X
				</button>


				</div>
			  {true && <div className=' flex items-center w-8/12 justify-center flex-col  shadowHere p-4 '>

			  

				  <div className='text-2xl text-center w-full'> Edit Data</div>
	   
	   
				   <div className='flex items-center w-6/12  m-1  rounded-md  '>
	   
	   <input className='w-full  text-2xl rounded-md p-2 ' onChange={(e)=>setTitle(e.target.value)} 
	   placeholder='Image Title' value={title} type="text" style={{fontSize:'16px'}} />
	   </div>
	   
	   <div className='flex items-center w-6/12  m-1  rounded-md  '>
	   
	   <input className='w-full  text-2xl rounded-md p-2' onChange={(e)=>setDesc(e.target.value)} placeholder='Image Description' value={desc} type="text" style={{fontSize:'16px'}} />
	   </div>
				  
				   <div className='flex items-center w-6/12  m-1  rounded-md  '>
	   
	   <input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setUrl(e.target.value)} placeholder='Thumbnail Image URL' value={imgUrl} type="text" style={{fontSize:'16px'}} />
	   </div>
	   
	   <div className='flex items-center w-6/12  m-1  rounded-md  '>
	   
	   <input className='w-full  text-2xl rounded-md p-2   ' onChange={(e)=>setShowUrl(e.target.value)} placeholder='Download Image Url High Res..' value={showurl} type="text" style={{fontSize:'16px'}} />
	   </div>
	   
	   <div className='flex items-center w-6/12  m-1  rounded-md  '>
	   
	   <input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setDwn1(e.target.value)} placeholder='PSD URL Image' value={dwn1} type="text" style={{fontSize:'16px'}} />
	   </div>
	   <div className='flex items-center w-6/12  m-1  rounded-md  '>
	   
	   <input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setDwn2(e.target.value)} placeholder='AI URL Image' value={dwn2} type="text" style={{fontSize:'16px'}} />
	   </div>
	   <div className='flex items-center w-6/12  m-1  rounded-md  '>
	   
	   <input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setDwn3(e.target.value)} placeholder='CDR URL Image' value={dwn3} type="text" style={{fontSize:'16px'}} />
	   </div>
	   <div className='flex items-center w-6/12  m-1  rounded-md  '>
	   
	   <input className='w-full  text-2xl rounded-md p-2  ' onChange={(e)=>setRar(e.target.value)} placeholder='RAR URL File' value={rar} type="text" style={{fontSize:'16px'}} />
	   </div>
	   
	   <div className='flex items-center w-6/12  m-1  rounded-md  '>
	   
	   <input className='w-full  text-2xl rounded-md p-2   ' onChange={(e)=>setSize(e.target.value)} placeholder='Image Details' value={size} type="text" style={{fontSize:'16px'}} />
	   </div>
	
	   <div className='flex items-center w-6/12  m-1  rounded-md  '>
	   
	   <input className='w-full  text-2xl rounded-md p-2   '  value={`uploading By ${localStorage.getItem('userName')}`} type="text" style={{fontSize:'16px'}} />
	   </div>
	   
	   <div  onClick={()=>UpdateData(8)}
	   className=' w-6/12  m-1  rounded-md  bg-green-400 flex items-center justify-center text-xl text-white p-2 mt-2 cursor-pointer ' >
	   UPDATE
	   {/* <input className='w-full  text-2xl rounded-md  ' onChange={(e)=>setDwn3(e.target.value)} placeholder='Download Url3' value={dwn3} type="text" /> */}
	   </div>
	   
	   
				   
			   </div>
			   }
	   
	   
	   
		   </div>
				}
		   </>
			}
				
				
			</div>
			

		

			
		</div>
	);
};

const App = (props) => {
	const [isModalOpen, setModalIsOpen] = useState(false);

	console.log(useState("hello")[1])
	const toggleModal = () => {
		setModalIsOpen(!isModalOpen);
	};

	return (
		<>
			{props.currentIndex==props.index &&<Modal data={props} data2={props.data} index={props.index} onRequestClose={toggleModal} url={props.url} />}


			{/* <div className=' p-2 px-5 text-sm rounded bg-black' onClick={toggleModal} type="button" >
				
			</div> */}



		</>
	);
};


export default App