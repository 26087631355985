import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import PopupModal from './popup';
import axios from 'axios';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

export default function Wal() {

  const [data, setData] = useState([])
  const [showPopup, setShowPop] = useState(false)
  const [loginId, setLoginId] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [userError, setUserError] = useState(null);


  const [tokenData, setTokenData] = useState(localStorage && localStorage.getItem('userToken'))




  useEffect(() => {
    checkUserVerification();
  });
  const checkUserVerification = async () => {
    const fetchData = await axios.post(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/eventlist`, {})

    setData(fetchData.data)
  };


  const handleLogin = async () => {

    const result = await axios.post(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/logincreative`, {
      userEmail: loginId,
      userPassword
    })

    console.log(result.data)
    if (result.status == 201) {
      setUserError("email id or password is wrong")
    }
    if (result.status == 200) {
      localStorage.setItem('userEmail', result.data.userEmail)
      localStorage.setItem('userName', result.data.userName)

      localStorage.setItem('userToken', result.data.userToken)

      localStorage.setItem('userType', result.data.userType)

      window.location.reload()

    }

  }
  const togglePopup = () => {

    setShowPop(true)
  }



  return (
    <div >


      {showPopup && !tokenData && <PopUp closePopup={setShowPop} />}


      {true &&

        <div className="tonni_tm_all_wrap flex flex-col items-center justify-center" data-magic-cursor="show">


<div className='flex  w-full  '>

<div>
  <iframe 
  className=''
  style={{height:'400px',width:window&& window.innerWidth}}
  src="https://player.vimeo.com/video/807637705?h=8333b412ed&amp;badge=0&amp;loop=1&amp;player_id=0&amp;app_id=58479"
   frameborder="0" allow="autoplay; fullscreen;" 
  allowfullscreen title="Web Site Intro Page Slides - AE [3840 X 1080] - V2.mp4">
    </iframe></div><script src="https://player.vimeo.com/api/player.js"></script>

</div>

          <div className="tonni_tm_section flex items-center justify-center" id="about">
            <div className="tonni_tm_about flex items-center justify-center">
              <div className="container  max-w-6xl flex flex-col  items-center justify-center">
           
                <div className="about_inner flex  flex-col justify-center w-fulll ">





<div className='flex'>
                  <div className="left">
                    <div className="left_inner">
                      <div className="year">
                        <h3>8</h3>
                        <span className="rounded"><img src="img/about/flower.png" alt="" /></span>
                      </div>
                      <div className="experience">
                        <h3>+</h3>
                        <p>years experience  </p>
                        <span className="shape"><img src="img/about/dots.png" alt="" /></span>
                        <span className="circle_shape"><img className="svg" src="img/svg/icon1.svg" alt="" /></span>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="title">

                      <h3>ABOUT US</h3>
                    </div>
                    <div className="text">
                      <p className='  '>We are a premier Event Management & Production Solution provider with seamless services worldwide. Over 10 years, the company has developed a world-class reputation for producing bespoke events and experiences for cross-sector global brands and organizations.
<br /> <br />
One of the primary tasks associated with any event is to create the look and feel of the event, these include creating the Themes, moodboards, collaterals as well as the designing the layout among the few works of this segment. We take pride in the creative and design work that we have been successfully executing over the years across client events and various other requirements.
<br /><br />
This space is dedicated to showcasing the works from our creative desk and the team behind these masterpieces.

                       


                      </p>
                    </div>

                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
          {/* <div className="container max-w-6xl flex items-center justify-center">
            <div className="tonni_tm_counter_list wow fadeInUp" data-wow-duration="1s">
              <ul>
                <li>

                  <div className="list_inner">
                    <span className="title">Number of  <br /> Clients </span>
                    <h3><span className="tonni_tm_counter" data-from="0" data-to="300" data-speed="2000">0</span>+</h3>
                  </div>
                </li>
                <li>
                  <div className="list_inner">
                    <span className="title"> Number of <br /> Events</span>
                    <h3><span className="tonni_tm_counter" data-from="0" data-to="4786" data-speed="2000">0</span>+</h3>
                  </div>
                </li>
                <li>
                  <div className="list_inner">
                    <span className="title">Number Of Designs Created</span>
                    <h3><span className="tonni_tm_counter" data-from="0" data-to="600" data-speed="2000">K</span> K+ </h3>
                  </div>
                </li>
                <li>
                  <div className="list_inner">
                    <span className="title">Number Of <br /> Themes</span>
                    <h3><span className="tonni_tm_counter" data-from="0" data-to="500" data-speed="2000">0</span>+</h3>
                  </div>
                </li>
              </ul>
            </div>


          </div> */}

          <div className=' w-full flex flex-col  items-center justify-center  ' >

            <div className='flex text-4xl text-black font-bold  mb-14'>

<p style={{fontSize:"22px"}}>THE BRANDS WHO TRUST US</p>
              </div>


            <div className='flex w-full  items-center justify-center  flex-wrap '>
              { tokenData &&<Link to={`/gallery/all`} >
                <div className="border m-2 flex items-center justify-center bg-black text-white" style={{ height: '150px', width: '150px' }} alt="" >ALL IMG </div></Link>}


              { !tokenData &&
                <div onClick={() => setShowPop(true)} className="border m-2 flex items-center justify-center bg-black text-white" style={{ height: '150px', width: '150px' }} alt="" >ALL IMG </div>}
                

              {data && data.map((item, index) => <div key={index}>

                {tokenData && <Link to={`/gallery/${item.eventId}/${item.eventname}`} key={index}>
                  <img className="border m-2" style={{ height: '150px', width: '150px' }} src={item.url} alt="" /> </Link>
                }
                {!tokenData &&
                  <img onClick={() => setShowPop(true)} className="border m-2" style={{ height: '150px', width: '150px' }} src={item.url} alt="" />
                }
              </div>

              )}
            </div>

          </div>







        </div>

      }

    </div>
  )
}




const PopUp = (props) => {
  const [loginId, setLoginId] = useState(null);

  const [lastName, setLastName] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [userError, setUserError] = useState(null);
  const handleLogin = async () => {

    const result = await axios.post(`http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3002/logincreative`, {
      userEmail: loginId,
      userPassword
    })

    console.log(result.data)
    if (result.status == 201) {
      setUserError("email id or password is wrong")
    }
    if (result.status == 200) {
      localStorage.setItem('userEmail', result.data.userEmail)
      localStorage.setItem('userName', result.data.userName)

      localStorage.setItem('userToken', result.data.userToken)

      localStorage.setItem('userType', result.data.userType)

      window.location.reload()

    }

  }
  //  alert(8)
  return (<div className='popup'>
    <div className='popup_inner'>
      <div></div>


      {<div className='flex items-center justify-center'>

        <div className='bg-white flex flex-col p-4  rounded-md '>
          <div className='text-black w-full text-right font-extrabold' onClick={() => props.closePopup(false)}>X</div>
          <div className='flex items-center justify-center font-bold w-full text-black text-2xl text-center  m-1    '>
            Login
          </div>

          <div className='flex items-center w-full  m-1 border   '>

            <input className='w-full  text-2xl p-2    ' onChange={(e) => setLoginId(e.target.value)} placeholder='Enter Your Email-ID' value={loginId} type="email" />
          </div>
          <div className='flex items-center w-full  m-1    '>

            <input className='w-full  text-2xl p-2   ' onChange={(e) => setUserPassword(e.target.value)} placeholder=' Enter Your Password' value={userPassword} type="password" />
          </div>

          <div

            onClick={() => handleLogin()}
            className=' w-full  m-1  rounded-md  bg-black flex items-center justify-center text-xl text-white p-2 mt-2 cursor-pointer ' >
            SUBMIT

          </div>
          <div className='text-red-500 w-full text-center font-bold'>{userError}</div>
        </div>



      </div>}

    </div>
  </div>
  );
}
